interface link {
  title: string
  url: string
}

const links: link[] = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Products",
    url: "/odour-neutraliser-products/",
  },

  {
    title: "Applications",
    url: "/applications/",
  },
  {
    title: "Find a Retailer",
    url: "/retailers/",
  },
  {
    title: "Distributors",
    url: "/distributors/",
  },
]

export default links
