import React, { createContext, FC, ReactNode } from "react"
import style from "./layout.module.scss"
import "../styles/global.scss"
import Header from "./header/header"
import { useLayout, layoutHookReturns } from "./layout-hooks/useLayout"
import Footer from "./footer/footer"
import CookieConsent from "../components/utilities/cookie-consent/cookieConsent"

// Layout component props
interface layoutProps {
  children: ReactNode
  className?: string
  title?: string
  darkMode?: boolean
  darkHeader?: boolean
  desktopHeader?: boolean
  titleDisappearOnScroll?: boolean
  noFooter?: boolean
}

// layout context types
interface layoutContext extends layoutHookReturns {
  title?: string
  darkMode?: boolean
}

// Initialise the layout context
export const LayoutContext = createContext<Partial<layoutContext>>({})

const Layout: FC<layoutProps> = ({
  children,
  className,
  title,
  darkMode,
  darkHeader,
  desktopHeader,
  titleDisappearOnScroll,
  noFooter,
}) => {
  // bring in layout logic from layout hook
  const { usingKeyboard, setHeaderColor, ...layout } = useLayout()

  // Combine title and layout hook for context
  // desktopHeader for dark header on desktop only
  const context = { ...layout, title, darkMode, desktopHeader, setHeaderColor }

  return (
    <LayoutContext.Provider value={context}>
      <div
        className={`${style.layout} ${className ? className : ""} ${
          usingKeyboard ? style.usingKeyboard : ""
        } ${darkMode ? style.darkMode : ""} ${
          darkHeader ? style.darkHeader : ""
        } ${desktopHeader ? style.desktopHeader : ""}`}
      >
        <Header titleDisappearOnScroll={titleDisappearOnScroll} />
        <main className={style.main}>{children}</main>
        {!noFooter && <Footer />}
        <CookieConsent />
      </div>
    </LayoutContext.Provider>
  )
}

export default Layout
