import React, { useEffect, useState } from "react"
import { useLocalStorage } from "../../hooks/localStorage"
import { useGetScrollPosition } from "../../hooks/useGetScrollPosition"
import LinkButton from "../buttons/link-button/linkButton"
import Button from "../buttons/primary-button/primaryButton"
import style from "./cookieConsent.module.scss"

const CookieConsent = () => {
  // Use local storage hook to hold state
  const [cookiesEnabled, setCookiesEnabled] = useLocalStorage("cookiesEnabled")

  //   Hold wether triggered internally
  const [triggered, setTriggered] = useState<boolean | null>(null)

  //   Get the scroll position from the hook
  const scrollPosition = useGetScrollPosition()

  useEffect(() => {
    if (scrollPosition < 300 || triggered) {
      return
    }
    setTriggered(true)
    // Remove cookie banner after 3 seconds
    setTimeout(() => {
      setCookiesEnabled(true)
    }, 3000)
  }, [scrollPosition])

  useEffect(() => {
    setTimeout(() => {
      setTriggered(false)
    }, 500)
  }, [])

  //   Return null if cookies enabled
  if (cookiesEnabled) return null

  return (
    <div
      className={`${style.cookieContainer} ${
        triggered === false ? style.enter : ""
      } ${triggered ? style.close : ""}`}
    >
      <div className={style.content}>
        <p>
          By using our website you consent to the cookies we use to improve your
          experience.
        </p>
        <div className={style.buttons}>
          <LinkButton
            href="/privacy-policy"
            small
            className={style.privacyLink}
          >
            View Privacy Policy
          </LinkButton>
          <Button onClick={() => setCookiesEnabled(true)} small>
            I understand
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CookieConsent
