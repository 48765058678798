import { Link } from "gatsby"
import React, { useContext } from "react"
import { LayoutContext } from "../layout"
import style from "./footer.module.scss"
import { footerLinks } from "./footerLinks"

const Footer = () => {
  const { darkMode } = useContext(LayoutContext)

  return (
    <footer className={`${style.footer} ${darkMode ? style.darkMode : ""}`}>
      <div className={style.linkContainer}>
        <ul className={style.links}>
          {footerLinks.main.map(link => (
            <li className={style.link} key={`footerLinks_${link.url}`}>
              <Link to={link.url}>{link.label}</Link>
            </li>
          ))}
        </ul>
        <ul className={style.linksSocial}>
          {/* Social media links */}
          <li className={style.link}>
            <ul className={style.socialLinks}>
              {footerLinks.socialMedia.map(link => (
                <li
                  className={style.socialLinkItem}
                  key={`footerSocialMediaLinks_${link.url}`}
                >
                  <a
                    href={link.url}
                    className={style.socialLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={link.icon}
                      alt={link.alt}
                      className={style.icon}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </li>
        </ul>
        <span className={`${style.author}`}>
          Site developed by{" "}
          <a
            href="https://www.npk.media"
            target="_blank"
            rel="noreferrer noopener"
          >
            NPK Media
          </a>
        </span>
      </div>

    </footer>
  )
}

export default Footer