import React, { FC } from "react"
import { buttonProps } from "../button"
import style from "../button.module.scss"

interface normalButtonProps extends buttonProps {
  onClick: () => void
}

const Button: FC<normalButtonProps> = ({
  children,
  onClick,
  center,
  className,
  color,
  small,
}) => {
  return (
    <button
      onClick={onClick}
      className={`${style.button} ${className || ""}
      ${small ? style.small : ""}
      ${color ? style[color] : ""} ${center ? style.center : ""}`}
    >
      {children}
    </button>
  )
}

export default Button
