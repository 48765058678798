import { motion } from "framer-motion"
import { Link } from "gatsby"
import React, { useContext } from "react"
import { LayoutContext } from "../../../layout"
import style from "./drawerMenu.module.scss"
import links from "./navItems"
import logo from "../../../../images/LogoOCD-01.svg"
import exit from "../../../../images/close-rounded-white-18dp.svg"
import { navAnimation, skrimAnimation } from "./drawerMenuAnimations"

const DrawerMenu = () => {
  // Bring in nav logic from context
  const { navOpen, setNavOpen } = useContext(LayoutContext)

  // Value to assign to the animations
  const activeAnimValue = navOpen ? "visible" : "hidden"

  // Wrap nav with scrim that will dim the background and close nav on click
  return (
    <div
      key="nav-drawer-scrim"
      className={`${style.container} ${navOpen && style.open}`} // Toggle open css class with the nav open state
    >
      {/* Skrim container */}
      <motion.div
        className={style.skrim}
        variants={skrimAnimation}
        initial="hidden"
        animate={activeAnimValue}
        transition={{
          stiffness: 600,
          damping: 50,
          mass: 1.2,
        }}
        onClick={() => {
          setNavOpen && setNavOpen(false)
        }}
      />
      {/* Nav container */}
      <motion.nav
        key="nav-drawer-menu"
        className={style.nav}
        variants={navAnimation}
        initial="hidden"
        animate={activeAnimValue}
        // Animation properties
        transition={{
          stiffness: 0.9,
        }}
      >
        {/* Nav header section -- holds close button and logo */}
        <header className={style.navHeader}>
          {/* Close nav button */}
          <button
            // Close on click
            onClick={() => setNavOpen && setNavOpen(false)}
            className={style.exitWrapper}
            title="close menu"
          >
            {/* Exit icon */}
            <img
              src={exit}
              alt="exit icon - closes menu"
              className={style.exit}
            />
          </button>
          {/* Link to homepage */}
          <Link to="/" className={style.title}>
            OCD
          </Link>
          <div className={style.inner}>
            {/* Logo -- links to home */}
            <Link to="/" className={style.logoWrapper}>
              <img
                className={style.logo}
                src={logo}
                alt="OCD Logo, links to home page"
              />
            </Link>
          </div>
        </header>
        {/* Nav menu */}
        <ul className={style.menu}>
          {/* Map over links from imported list */}
          {links.map(link => (
            <li className={style.navItem} key={`navLinkItem_${link.url}`}>
              {/* Link to relevant page */}
              <Link
                to={link.url}
                className={style.link}
                // Add class when link is active
                activeClassName={style.linkActive}
              >
                {link.title}
              </Link>
            </li>
          ))}
        </ul>
        <span className={style.author}>
          Website by{" "}
          <a
            href="https://www.npk.media"
            target="_blank"
            rel="noreferrer noopener"
          >
            NPK Media
          </a>
        </span>
      </motion.nav>
    </div>
  )
}

export default DrawerMenu
