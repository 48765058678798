import { Dispatch, SetStateAction, useState } from "react"
import { useUsingKeyboard } from "./useUsingKeyboard"

export interface layoutHookReturns {
  navOpen: boolean
  setNavOpen: Dispatch<SetStateAction<boolean>>
  usingKeyboard: boolean
  headerColor: string | null
  setHeaderColor: Dispatch<SetStateAction<string | null>>
}

export const useLayout = (): layoutHookReturns => {
  // Hold nav state inside the hook state
  const [navOpen, setNavOpen] = useState<boolean>(false)

  //   Get keyboard user logic
  const usingKeyboard = useUsingKeyboard()

  // Hold logic for header color in local state
  const [headerColor, setHeaderColor] = useState<string | null>(null)

  //   Hold all within an object
  const layout = {
    navOpen,
    setNavOpen,
    usingKeyboard,
    headerColor,
    setHeaderColor,
  }

  //   Return the object
  return layout
}
