import { useEffect, useState } from "react"

// Handle the user using the keyboard, return a boolean
export const useUsingKeyboard = (): boolean => {
  // Hold whether using the keyboard within the state
  const [usingKeyboard, setUsingKeyboard] = useState<boolean>(false)

  //   Callback for using the keyboard
  const handleKeyboard = (e: any) => {
    //   Check if tab key was pressed
    if (e.keyCode === 9) {
      setUsingKeyboard(true)
    }
  }

  //   Callback for clicking on the page
  const listenForClick = () => {
    setUsingKeyboard(false)
  }

  //   First render set up listeners
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("keyup", handleKeyboard)
      window.addEventListener("click", listenForClick)
    }
    // Remove listeners on unmount
    return () => {
      window.removeEventListener("keyup", handleKeyboard)
      window.removeEventListener("click", listenForClick)
    }
  }, [])

  return usingKeyboard
}
