import { Link } from "gatsby"
import React, { FC } from "react"
import { buttonProps } from "../button"
import buttonStyle from "../button.module.scss"

interface linkButtonProps extends buttonProps {
  href: string
  external?: boolean
}

const LinkButton: FC<linkButtonProps> = ({
  href,
  external,
  children,
  className,
  center,
  color,
  small,
}) => {
  // Check if link is external
  return external ? (
    <a
      href={href}
      className={`${buttonStyle.button}
      ${small ? buttonStyle.small : ""}
      ${color ? buttonStyle[color] : ""} ${className ? className : ""} ${
        center ? buttonStyle.center : ""
      }`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  ) : (
    <>
      {/* If not external use Gatsby internal link */}
      <Link
        to={href}
        className={`${buttonStyle.button} ${color ? buttonStyle[color] : ""} ${
          className ? className : ""
        }
          ${small ? buttonStyle.small : ""}
          ${center ? buttonStyle.center : ""}`}
      >
        {children}
      </Link>
    </>
  )
}

export default LinkButton
