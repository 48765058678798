import instagramIcon from "../../images/instagram.svg"
import facebookIcon from "../../images/facebook.svg"
export const footerLinks = {
  main: [
    {
      url: "/",
      label: "Home",
    },
    {
      url: "/odour-neutraliser-products/",
      label: "Products",
    },
    {
      url: "/applications/",
      label: "Applications",
    },
    {
      url: "/retailers/",
      label: "Find a Retailer",
    },
    {
      url: "/distributors/",
      label: "Distributors",
    },
    {
      url: "/privacy-policy/",
      label: "Privacy Policy",
    },
  ],
  socialMedia: [
    {
      url: "https://www.instagram.com/ocdonline",
      alt: "Instagram icon",
      icon: instagramIcon,
    },
    {
      url:
        "https://www.facebook.com/OCD-Deo-Max-104223257747777/?__tn__=%2Cd%2CP-R&eid=ARBEfdxryo2O5Hr3gQ4C7o4hY-qx6rXKYzTATGdF8do0l7uLsLgTBpye0HtvoSQl8usBuy_OXnyJQHib",
      alt: "Facebook icon",
      icon: facebookIcon,
    },
  ],
}
