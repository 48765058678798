import React, { FC, useContext, useEffect, useState } from "react"
import style from "./header.module.scss"
import logo from "../../images/LogoOCD-01.svg"
import { Link } from "gatsby"
import Nav from "./nav/nav"
import { LayoutContext } from "../layout"
import { useGetScrollPosition } from "../../components/hooks/useGetScrollPosition"
import DesktopNav from "./nav/desktop-nav/desktopNav"

interface headerProps {
  titleDisappearOnScroll?: boolean
}

const Header: FC<headerProps> = ({ titleDisappearOnScroll }) => {
  // Get page title from context
  const { title } = useContext(LayoutContext)

  // Get header color from hook logic
  const headerColor = useGetHeaderColor()

  // Function to set header bg color
  const getHeaderColor = () => ({
    background: headerColor,
    ...(headerColor !== "transparent" && {
      boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.16)",
    }),
  })

  const scrollPosition = useGetScrollPosition()

  return (
    <header className={style.header} style={getHeaderColor()}>
      <div className={style.details}>
        {/* Add in nav */}
        <Nav />

        {/* Page title (if set) */}
        {title && (
          <span
            style={{
              opacity: titleDisappearOnScroll
                ? scrollPosition > 75
                  ? 0
                  : 1
                : 1,
            }}
            className={style.title}
          >
            {title}
          </span>
        )}
      </div>
      <DesktopNav />
      {/* Logo -- links to home */}
      <Link to="/" className={style.logoWrapper}>
        <img
          className={style.logo}
          src={logo}
          alt="OCD Logo, links to home page"
        />
      </Link>
    </header>
  )
}

export default Header

const useGetHeaderColor = () => {
  // extract header color from layout context
  const { headerColor } = useContext(LayoutContext)

  // Hold colour in local state
  const [color, setColor] = useState<string>("transparent")

  // get scroll position using hook
  const scrollPosition = useGetScrollPosition()

  // Guard clause for client side and context defined
  if (typeof headerColor === "undefined") return color

  // Update color on scroll and change of colour
  useEffect(() => {
    setColor(
      scrollPosition > 120 ? headerColor || "transparent" : "transparent"
    )
  }, [headerColor, scrollPosition])

  return color
}
