import React from "react"
import { useContext } from "react"
import style from "./nav.module.scss"
import { LayoutContext } from "../../layout"
import hamburger from "../../../images/menu-rounded-black-18dp.svg"
import DrawerMenu from "./drawer-menu/drawerMenu"

const Nav = () => {
  const { setNavOpen } = useContext(LayoutContext)
  return (
    <>
      <button
        // Open nav on click
        onClick={() => setNavOpen && setNavOpen(true)}
        className={style.hamburgerWrapper}
      >
        {/* Hamburger icon */}
        <img
          src={hamburger}
          className={style.hamburger}
          alt="Hamburger icon - toggles menu open and closed"
        />
      </button>
      {/* Bring in the drawer menu */}
      <DrawerMenu />

    </>
  )
}

export default Nav
