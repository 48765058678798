export const skrimAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
  },
}

export const navAnimation = {
  hidden: {
    x: "-100%",
  },
  visible: {
    x: 0,
  },
}
