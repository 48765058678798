import { Link } from "gatsby"
import React from "react"
import style from './desktopNav.module.scss'
import links from "../drawer-menu/navItems"


const DesktopNav = () => {
  return (
    <>
      <ul className={style.menu}>
          {/* Map over links from imported list */}
          {links.map(link => (
            <li className={style.navItem} key={`navLinkItem_${link.url}`}>
              {/* Link to relevant page */}
              <Link
                to={link.url}
                className={style.link}
                // Add class when link is active
                activeClassName={style.linkActive}
              >
                {link.title}
              </Link>
            </li>
          ))}
        </ul>

    </>
  )
}

export default DesktopNav